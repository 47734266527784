<template>
  <!-- 商家号 -->
  <div class="box-card">
    <div>
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="商家号：">
          <el-input maxlength="30" v-model="queryForm.merchant_number" @keyup.enter.native="onSubmit" placeholder="请输入商家号"
            @input="(e) => (queryForm.merchant_number = validNumberLetter(e))"></el-input>
        </el-form-item>
        <el-form-item label="商家名称：">
          <el-input maxlength="30" v-model="queryForm.merchant_name" @keyup.enter.native="onSubmit" placeholder="请输入商家名称"
            @input="(e) => (queryForm.merchant_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="停车场：">
          <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
            placeholder="请选择停车场" @change="onSubmit()">
            <el-option v-for="item in getAllParkingList()" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号状态：">
          <el-select popper-class="my-select" clearable v-model="queryForm.status" filterable default-first-option
            placeholder="请选择账号状态" @change="onSubmit()">
            <!-- 1、正常 4、禁用 5、已过期 -->
            <el-option label="正常" :value="1"></el-option>
            <el-option label="已停用" :value="4"></el-option>
            <el-option label="已过期" :value="5"></el-option>
            <!-- <el-option v-for="item in getOptions('BusinessState')" :key="item.value" :label="item.name" :value="item.value">
              </el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="商家号">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('4e14db9f1e1a4c568b77aeeb')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="showAddShopsAccount = true"
              v-if="this.getUserInfo.user_name != 'admin' && isShowBtn('55e5c3c33c5049878e0be03d')">新增</lbButton>
          </div>
        </template>
        <div style="text-align:left;">
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option"  :page.sync="page"  @selection-change="selectionChange" @size-change="sizeChange"  @current-change="currentChange">
            <template  slot-scope="{row}" slot="coupon_count">
              <el-link type="primary" target="_blank" v-if="row.coupon_count > 0"
                  @click="openDialog(row._id)">{{ row.coupon_count }}</el-link>
                <span v-else>{{ row.coupon_count }}</span>
            </template>
            <template  slot-scope="{row}" slot="valid_time">
                {{ $moment( row.valid_time).format("YYYY-MM-DD") }}
            </template>
            <template  slot-scope="{row}" slot="status">
              <span :style="'color:' + businessStateColor(row.status)">
                  {{ row.status === 4 ? '已停用' : getWayName('BusinessState', row.status) }}
                </span>
            </template>
            <template  slot-scope="{row}" slot="checkin_time">
              {{ $moment( row.checkin_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editShopsAccount(row._id)"
                  v-if="isShowBtn('5929109bee0b46faa1ee13a0')">编辑</lbButton>
                <lbButton icon="zhongzhimima" hint="重置密码" @click="restPassWord(row)"
                  v-if="isShowBtn('454ed90f488747628549d61b')"></lbButton>
                <lbButton :type="row.status != 1 ? 'info' : 'setting'"
                  :hint="row.status != 1 ? '开启账号' : '关闭账号'" @click="batchChang(row)"
                  :icon="row.status != 1 ? 'kaiqizhanghu' : 'guanbizhanghu'"
                  v-if="isShowBtn('1642d7ad11ea4cf5ac7665c1')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="merchant_number" label="商家号"></el-table-column>
            <el-table-column prop="merchant_name" label="商家名称"></el-table-column>
            <el-table-column prop="parking_name" label="停车场"></el-table-column>
            <el-table-column prop="coupon_count" label="优惠劵张数" width="120">
              <template slot-scope="scope">
                <el-link type="primary" target="_blank" v-if="scope.row.coupon_count > 0"
                  @click="openDialog(scope.row._id)">{{ scope.row.coupon_count }}</el-link>
                <span v-else>{{ scope.row.coupon_count }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="valid_time" label="有效期至" width="120">
              <template slot-scope="scope">
                {{ $moment(scope.row.valid_time).format("YYYY-MM-DD") }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="账号状态" width="120">
              <template slot-scope="scope">
                <span :style="'color:' + businessStateColor(scope.row.status)">
                  {{ scope.row.status === 4 ? '已停用' : getWayName('BusinessState', scope.row.status) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template slot-scope="scope">
                {{ $moment(scope.row.checkin_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="editShopsAccount(scope.row._id)"
                  v-if="isShowBtn('5929109bee0b46faa1ee13a0')">编辑</lbButton>
                <lbButton icon="zhongzhimima" hint="重置密码" @click="restPassWord(scope.row)"
                  v-if="isShowBtn('454ed90f488747628549d61b')"></lbButton>
                <lbButton :type="scope.row.status != 1 ? 'info' : 'setting'"
                  :hint="scope.row.status != 1 ? '开启账号' : '关闭账号'" @click="batchChang(scope.row)"
                  :icon="scope.row.status != 1 ? 'kaiqizhanghu' : 'guanbizhanghu'"
                  v-if="isShowBtn('1642d7ad11ea4cf5ac7665c1')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <lebo-dialog append-to-body title="详情" :isShow="detailsDialog" style="text-align: start" width="33%"
      @close="detailsDialog = false" footerSlot>
      <el-table :data="detailsData" border class="table_box" max-height="500" height="460">
        <el-table-column prop="coupon_name" label="优惠劵名称"></el-table-column>
        <el-table-column prop="coupon_type" label="类型">
          <template slot-scope="scope">{{ getWayName('CouponType', scope.row.coupon_type) }}</template>
        </el-table-column>
        <el-table-column prop="coupon_count" label="张数"></el-table-column>
        <el-table-column label="有效期至">
          <template slot-scope="scope">
            {{ $moment(scope.row.valid_time).format("YYYY-MM-DD") }}
          </template>
        </el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 新增商户号 -->
    <lebo-dialog append-to-body title="新增商家号" :isShow="showAddShopsAccount" width="65%" @close="showAddShopsAccount = false"
      footerSlot>
      <addShopsAccount @closeDialog="closeShopsAccount" v-if="showAddShopsAccount"></addShopsAccount>
    </lebo-dialog>
    <!-- 编辑商户号 -->
    <lebo-dialog append-to-body title="编辑商家号" :isShow="showEditShopsAccount" width="65%"
      @close="showEditShopsAccount = false" footerSlot>
      <addShopsAccount @closeDialog="closeShopsAccount" :id="dialogShopsAccountId" v-if="showEditShopsAccount">
      </addShopsAccount>
    </lebo-dialog>
  </div>
</template>
<script>
import { addBusinessManagement, updateBusinessManagement, getBusinessManagementList, getBusinessDetail, getCouponsSelectList, setPassword, setBusinessStatus, delBusiness, getBusinessCouponsSelectList } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addShopsAccount from './components/addShopsAccount/index.vue'
export default {
  components: { addShopsAccount },
  data () {
    return {
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        parking_id: '',
        merchant_number: '',
        merchant_name: '',
        status: null // 状态 1、正常 4、禁用 5、已过期
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      detailsDialog: false,
      detailsData: [],
      showAddShopsAccount: false, // 新增商户弹框
      showEditShopsAccount: false,
      dialogShopsAccountId: '',
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '商家号',
            prop: 'merchant_number'
          }, {
            label: '商家名称',
            prop: 'merchant_name'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '优惠劵张数',
            prop: 'coupon_count',
            slot: true
          }, {
            label: '有效期至',
            prop: 'valid_time',
            slot: true
          }, {
            label: '账号状态',
            prop: 'status',
            slot: true
          }, {
            label: '创建时间',
            prop: 'checkin_time',
            slot: true
          }
        ]
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetBusinessManagementList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName', 'getAllParkingList', 'getUserInfo'])
  },
  watch: {},
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    businessStateColor (status) {
      // 1、正常 4、禁用 5、已过期
      switch (status) {
        // 'color: #bbb;': 'color: #67C23A;'">
        case 1: return '#67c23a'
        case 4: return '#bbb;'
        case 5: return '#ff0000;'
      }
    },
    async fnGetBusinessManagementList () {
      const res = await getBusinessManagementList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        parking_id: this.queryForm.parking_id,
        merchant_number: this.queryForm.merchant_number,
        merchant_name: this.queryForm.merchant_name,
        status: this.queryForm.status ? this.queryForm.status : 0
      })
      console.log(res)
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetBusinessManagementList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetBusinessManagementList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetBusinessManagementList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetBusinessManagementList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetBusinessManagementList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的商家号, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          // this.$msg.success('删除成功!');
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._id = arr.join(',')
              this.fndelBusiness(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的商家号！')
      }
    },
    async fndelBusiness (obj) {
      const res = await delBusiness(obj)
      if (res && res.Code === 200) this.$msg.success(res.Message)
      this.fnGetBusinessManagementList()
    },
    // 重置密码
    async restPassWord (row) {
      this.$confirm('是否重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await setPassword({
          _id: row._id,
          merchant_name: row.merchant_name
        })
        if (res && res.Code === 200) {
          this.$msg.success(res.Message)
          this.fnGetBusinessManagementList()
        }
      }).catch(() => {

      })
    },
    // 开启、关闭账号
    async batchChang (row) {
      console.log(row.status)
      this.$confirm(row.status !== 1 ? '是否开启账号？' : '是否关闭账号？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await setBusinessStatus({
          _id: row._id,
          status: row.status !== 1 ? 1 : 4,
          merchant_name: row.merchant_name
        })
        if (res && res.Code === 200) {
          this.$msg.success(res.Message)
          this.fnGetBusinessManagementList()
        }
      }).catch(() => {

      })
    },
    async openDialog (id) {
      console.log(id)
      const res = await getBusinessCouponsSelectList({
        _id: id
      })
      this.detailsData = res && res.Code === 200 && res.Data ? res.Data : []
      this.detailsDialog = true
    },
    closeShopsAccount () {
      this.showAddShopsAccount = false
      this.showEditShopsAccount = false
      this.fnGetBusinessManagementList()
    },
    editShopsAccount (id) {
      this.showEditShopsAccount = true
      this.dialogShopsAccountId = id
    },
    // 选中的商家号
    selectionChange (list) {
      this.multipleSelection = list
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

</style>
