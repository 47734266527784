/**
 * 优惠劵
 */

import axios from './http'

// 新增商家信息
export const addBusinessManagement = (data) => axios.post('/ParkingLotApi/BusinessManagement/Add', data)
// 修改商家信息
export const updateBusinessManagement = (data) => axios.post('/ParkingLotApi/BusinessManagement/Update', data)
// 获取商家信息列表
export const getBusinessManagementList = (params) => axios.get('/ParkingLotApi/BusinessManagement/GetPageList', { params: params })
// 获取商家信息详情
export const getBusinessDetail = (params) => axios.get('/ParkingLotApi/BusinessManagement/GetDetails', { params: params })
// 重置密码
export const setPassword = (data) => axios.post('/ParkingLotApi/BusinessManagement/SetPassword', data)
// 正常或禁用账号
export const setBusinessStatus = (data) => axios.post('/ParkingLotApi/BusinessManagement/SetBusinessStatus', data)
// 删除商家信息
export const delBusiness = (data) => axios.post('/ParkingLotApi/BusinessManagement/Delete', data)
// 获取商家信息可用的优惠券列表
export const getBusinessCouponsSelectList = (params) => axios.get('/ParkingLotApi/BusinessManagement/GetBusinessCoupons', { params: params })
// 获取优惠券列表
export const getCouponsSelectList = (params) => axios.get('/ParkingLotApi/Coupon/GetCouponsList', { params: params })

// 分页获取账号审核列表
export const getBusinessAuditList = (params) => axios.get('/ParkingLotApi/AuditBusiness/GetPageList', { params: params })
// 获取账号审核详情
export const getBusinessAuditDetail = (params) => axios.get('/ParkingLotApi/AuditBusiness/GetDetails', { params: params })
// 账号审核
export const auditBusiness = (data) => axios.post('/ParkingLotApi/AuditBusiness/AuditBusiness', data)

// 新增优惠券配置
export const addCoupon = (data) => axios.post('/ParkingLotApi/Coupon/Add', data)
// 修改优惠券配置
export const updateCoupon = (data) => axios.post('/ParkingLotApi/Coupon/Update', data)
// 修改优惠券配置
export const delCoupon = (data) => axios.post('/ParkingLotApi/Coupon/Delete', data)
// 分页获取优惠券列表
export const getCouponPageList = (params) => axios.get('/ParkingLotApi/Coupon/GetPageList', { params: params })
// 获取优惠券在用的停车场信息
export const getCouponParkingsList = (params) => axios.get('/ParkingLotApi/Coupon/GeCouponParkings', { params: params })

// 分页获取发券记录列表
export const getIssueCouponsPageList = (params) => axios.get('/ParkingLotApi/IssueCoupons/GetPageList', { params: params })
// 获取发券记录详情
export const getIssueCouponsDetails = (params) => axios.get('/ParkingLotApi/IssueCoupons/GetDetails', { params: params })

// 新增账号续费套餐
export const addAccountCombo = (data) => axios.post('/ParkingLotApi/AccountPackage/Add', data)
// 修改账号续费套餐
export const updateAccountCombo = (data) => axios.post('/ParkingLotApi/AccountPackage/Update', data)
// 删除账号续费套餐
export const delAccountCombo = (data) => axios.post('/ParkingLotApi/AccountPackage/Delete', data)
// 设置账号续费套餐上架或下架
export const setAccountComboStatus = (data) => axios.post('/ParkingLotApi/AccountPackage/SetPackageStatus', data)
// 分页获取账号续费套餐列表
export const getAccountComboPageList = (params) => axios.get('/ParkingLotApi/AccountPackage/GetPageList', { params: params })
// 获取账号续费套餐详情
export const getAccountComboDetails = (params) => axios.get('/ParkingLotApi/AccountPackage/GetDetails', { params: params })

// 新增优惠劵套餐
export const addCouponCombo = (data) => axios.post('/ParkingLotApi/CouponPackage/Add', data)
// 修改优惠劵套餐
export const updateCouponCombo = (data) => axios.post('/ParkingLotApi/CouponPackage/Update', data)
// 删除优惠劵套餐
export const delCouponCombo = (data) => axios.post('/ParkingLotApi/CouponPackage/Delete', data)
// 设置优惠劵套餐上架或下架
export const setCouponComboStatus = (data) => axios.post('/ParkingLotApi/CouponPackage/SetPackageStatus', data)
// 分页获取优惠劵套餐列表
export const getCouponComboPageList = (params) => axios.get('/ParkingLotApi/CouponPackage/GetPageList', { params: params })
// 获取优惠劵套餐详情
export const getCouponComboDetails = (params) => axios.get('/ParkingLotApi/CouponPackage/GetDetails', { params: params })

// 分页获取账号续费订单信息列表
export const getAccountOrderPageList = (params) => axios.get('/ParkingLotApi/YHOrderManage/GetAccountOrderPageList', { params: params })
// 获取账号续费订单信息详情
export const getAccountOrderDetail = (params) => axios.get('/ParkingLotApi/YHOrderManage/GetAccountOrderDetail', { params: params })
// 分页获取优惠券套餐订单信息列表
export const getCouponsOrderPageList = (params) => axios.get('/ParkingLotApi/YHOrderManage/GetCouponsOrderPageList', { params: params })
// 获取优惠券套餐订单信息详情
export const getCouponsOrderDetail = (params) => axios.get('/ParkingLotApi/YHOrderManage/GetCouponsOrderDetail', { params: params })
// 获取停车场已上架的优惠券套餐详情
export const getParkingCouponPackageList = (params) => axios.get('/ParkingLotApi/CouponPackage/GetParkingCouponPackageList', { params: params })
