<template>
  <!-- 新增商家号 -->
  <div class="box-card" style="text-align: left;margin-top: 0;">
    <el-form :model="batchAddForm" :rules="batchAddFormRules" ref="batchAddFormRef" label-width="120px" class="form_box">
      <div class="title">账号信息</div>
      <el-divider></el-divider>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="商家号：" prop="merchant_number">
            <div style="display: flex;">
              <el-input v-model="batchAddForm.merchant_number" placeholder="请输入商家号" maxlength="30"
                @input="(e) => (batchAddForm.merchant_number = validNumberLetter(e))"></el-input>
              <span style="margin-left:15px;color:#999;">默认密码：88888888</span>
            </div>
          </el-form-item>
          <el-form-item label="商家名称：" prop="merchant_name">
            <el-input v-model="batchAddForm.merchant_name" placeholder="请输入商家名称" maxlength="30"
              @input="(e) => (batchAddForm.merchant_name = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="身份证：" prop="idcard">
            <el-input v-model="batchAddForm.idcard" placeholder="请输入身份证号" maxlength="18"
              @input="(e) => (batchAddForm.idcard = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="所在地址：" prop="address">
            {{ batchAddForm.address }}
            <!-- <el-input style="margin-right: 15px;" v-model="batchAddForm.position" disabled v-show="batchAddForm.position" @input="(e) => (batchAddForm.position = validSpace(e))"></el-input> -->
            <lbButton type="warning" size="small" @click="openMap">{{ batchAddForm.address ? '重新选择' : '地图选择' }}</lbButton>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="有效期：" prop="valid_time">
            <el-date-picker value-format="yyyy-MM-dd" :picker-options="pickerOptions" v-model="batchAddForm.valid_time"
              type="date" placeholder="请选择有效期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="负责人：" prop="principal">
            <el-input placeholder="请输入负责人姓名" v-model="batchAddForm.principal" maxlength="10"
              @input="(e) => (batchAddForm.principal = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input placeholder="请输入手机号" v-model="batchAddForm.phone" maxlength="11"
              @input="(e) => (batchAddForm.phone = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="营业执照：" prop="business_license">
            <div style="display: flex;">
              <el-upload class="logo-upload" action="''" :http-request="upload" :show-file-list="false"
                accept="image/jpeg,image/jpg,image/png" :before-upload="beforeAvatarUpload">
                <lbButton size="small" class="look_btn" @click.prevent.stop="showPreviewPicture()"
                  style="margin-right:10px;" v-if="batchAddForm.business_license">查看</lbButton>
                <lbButton type="warning" size="small">{{ batchAddForm.business_license ? '重新上传' : '上传' }}</lbButton>
              </el-upload>
              <span style="margin-left:15px;color:#999;">图片格式：jpg、jpeg、png，大小&lt;5M </span>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="经营范围：" prop="business_range">
            <el-input type="textarea" v-model="batchAddForm.business_range" maxlength="300" :rows="2"
              placeholder="请输入经营范围" @input="(e) => (batchAddForm.business_range = validSpacing(e))"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="停车场：" prop="parking_id" v-if="!id">
            <el-select popper-class="my-select" v-model="batchAddForm.parking_id" filterable placeholder="请选择停车场"
              @change="parkingChange">
              <el-option v-for="item in getAllParkingList()" :key="item.parking_id" :label="item.parking_name"
                :value="item.parking_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="title">{{ id ? '优惠劵' : "附赠优惠劵" }}</div>
      <el-divider></el-divider>
      <div v-if="id">
        <el-form-item label="停车场：" style="margin-bottom:0px;">{{ batchAddForm.parking_name }}</el-form-item>
        <el-table class="tablestyle" max-height="160" :data="batchAddForm.coupons" border>
          <el-table-column prop="coupon_name" label="优惠劵名称"></el-table-column>
          <el-table-column prop="coupon_type" label="类型">
            <template slot-scope="scope">{{ getWayName('CouponType', scope.row.coupon_type) }}</template>
          </el-table-column>
          <el-table-column prop="coupon_count" label="张数"></el-table-column>
          <el-table-column prop="valid_time" label="有效期至">
            <template slot-scope="scope">
              {{ $moment(scope.row.valid_time).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item label="优惠劵：" v-else>
        <div v-for="(item, index) in batchAddForm.coupons" :key="index" style="display:flex;margin-bottom:20px;" class="couponsBox">
          <el-row >
            <el-col :span="7">
              <el-form-item>
                <el-select v-model="item.coupon_id" popper-class="my-select" placeholder="请选择优惠劵" filterable clearable
                  @change="couponChange(item.coupon_id, index)">
                  <el-option v-for="j in couponsSelectList" :key="j._id" :label="j.coupon_name"
                    :value="j._id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :prop="`coupons[${index}].coupon_count`"
                :rules="[{ required: batchAddForm.coupons[index].coupon_id, message: '请输入数量', trigger: 'blur' }]">
                <el-input v-model="item.coupon_count" placeholder="请输入数量" class="NumberInput" maxlength="8"
                  @input="(e) => (item.coupon_count = validNumberInteger(e))"></el-input>
                  <span style="margin-left:15px;color:#999;">张</span>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :prop="`coupons[${index}].valid_day`"
                :rules="[{ required: batchAddForm.coupons[index].coupon_id, message: '请输入优惠劵时效', trigger: 'blur' }]"
                style="margin-left:18px">
                <el-input v-model="item.valid_day" placeholder="请输入优惠劵时效" maxlength="8"
                  @input="(e) => (item.valid_day = validNumberInteger(e))"></el-input>
                  <span style="margin-left:15px;color:#999;">天</span>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button style="margin-left:15px;padding:8px!important;" icon="el-icon-plus" size="mini" circle
                  @click="addCoupons()"></el-button>
                <el-button style="padding:8px!important;" icon="el-icon-minus" size="mini" circle v-if="index > 0"
                  @click="delCoupons(index)"></el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </div>
      </el-form-item>
    </el-form>
    <!-- 地图标注对话框 -->
    <lebo-dialog append-to-body class="mapDialog" width="50%" title="选择地图坐标" :isShow="addressInnerVisible"
      @close="closeAddress" footerSlot>
      <div class="searchMap">
        <!-- <el-input id="inputkeyword" v-model="keyword" placeholder="请输入关键字" @input="(e) => (keyword = validSpace(e))"></el-input>
          <lbButton type="orign" @click="getAdress">搜 索</lbButton> -->
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.province_code" filterable clearable
              placeholder="请选择省" @change="provinceChange">
              <el-option v-for="item in provinceSelectList" :key="item.specific_code" :label="item.specific_name"
                :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.city_code" filterable clearable placeholder="请选择市"
              @change="cityChange">
              <el-option v-for="item in citySelectList" :key="item.specific_code" :label="item.specific_name"
                :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.district_code" filterable clearable
              placeholder="请选择区" @change="districtChange">
              <el-option v-for="item in districtSelectList" :key="item.specific_code" :label="item.specific_name"
                :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input id="inputkeyword" v-model="keyword" placeholder="请输入关键字"
              @input="(e) => (keyword = validSpace(e))"></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-button size="small" type="primary" @click="getAdress">搜索</el-button>
          </el-form-item> -->
        </el-form>
      </div>
      <div id="amap_box" class="all-amap"></div>
    </lebo-dialog>
    <!-- 图片预览 -->
    <el-image-viewer v-if="isShowPreviewPicture" :on-close="onClosePreviewPicture" :url-list="previewPictureList"
      :z-index="9999" />
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm" v-preventReClick>保 存
    </lbButton>
  </div>
</template>
<script>
import { addBusinessManagement, updateBusinessManagement, getBusinessManagementList, getBusinessDetail, getCouponsSelectList, setPassword, setBusinessStatus, delBusiness, getBusinessCouponsSelectList } from '@/api/discountCoupon'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { mapMutations, mapGetters, mapState } from 'vuex'
import request from '@/api/systemapi'
import { getProvinceList } from '@/api/administrative'
import addressjs from '@/utils/city.js'
// // 导入组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  props: ['id'],
  components: {
    ElImageViewer
  },
  data () {
    return {
      batchAddForm: {
        merchant_number: '', // 商家号
        password: '88888888', // 密码
        merchant_name: '', // 名称
        valid_time: '', // 有效期
        phone: '', // 电话号码
        idcard: '', // 身份证
        address: '', // 地址
        longitude: '', // 经度
        latitude: '', // 纬度
        business_license: '', // 营业执照
        business_range: '', // 经营范围
        parking_id: '',
        parking_name: '', // 停车场名字
        principal: '', // 负责人
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: '',
        coupons: [{
          coupon_id: '', // 优惠券id
          coupon_name: '', // 优惠券名称
          coupon_type: null, // 优惠券类型
          coupon_count: null, // 优惠券数量
          valid_day: null, // 优惠券有效天数
          valid_time: '' // 有效期日期（当前时间+有效天数 23：59：59）
        }] // 优惠券
      },
      batchAddFormRules: {
        merchant_number: [{ required: true, message: '请输入商家号', trigger: 'blur' },
          { pattern: /^[A-Za-z0-9]{6,14}$/i, message: '6-14个字符，仅支持字母或数字，不区分大小写', trigger: 'blur' }],
        merchant_name: [{ required: true, message: '请输入商家名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入商家地址', trigger: 'blur' }],
        valid_time: [{ required: true, message: '请选择有效期', trigger: 'blur' }],
        principal: [{ required: true, message: '请输入负责人姓名', trigger: 'blur' },
          { pattern: /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/, message: '请输入中文', trigger: 'blur' }
        ],
        parking_id: [{ required: true, message: '请选择停车场', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }],
        business_license: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
        business_range: [{ required: true, message: '请输入经营范围', trigger: 'blur' }],
        idcard: [{ required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/, message: '请输入正确的身份证号', trigger: 'blur' }]
      },
      isRequired: false,
      // 是否展示地图对话框
      addressInnerVisible: false,
      keyword: '',
      // 地图对象
      map: {},
      // 地图位置信息对象
      infoWindow: {},
      // 标记点对象
      marker: {},
      geocoder: {},
      // 是否预览图片
      isShowPreviewPicture: false,
      // 预览图片列表
      previewPictureList: [],
      couponsSelectList: [],
      pickerOptions: {
        disabledDate (time) {
          //  如果没有后面的-8.64e6就是不可以选择今天的
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      addressInfo: {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      },
      provinceSelectList: [], // 省份下拉列
      citySelectList: [], // 市下拉列
      districtSelectList: [], // 区下拉列
      autocomplete: null
    }
  },
  created () {
  },
  mounted () {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(new Date().getDate() + 30)
    this.batchAddForm.valid_time = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate()
    this.fnGetCouponsSelectList()
    if (this.id) {
      this.fngetBusinessDetail()
    }
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fnGetCouponsSelectList () {
      const res = await getCouponsSelectList()
      this.couponsSelectList = res && res.Code === 200 && res.Data ? res.Data : []
    },
    async fngetBusinessDetail () {
      const res = await getBusinessDetail({
        _id: this.id
      })
      console.log('当前位置', res)
      if (res && res.Code === 200 && res.Data) {
        this.batchAddForm = res.Data
        this.batchAddForm.valid_time = this.$moment(res.Data.valid_time).format('YYYY-MM-DD')
      }
    },
    parkingChange (val) {
      var list = this.getAllParkingList()
      var index = list.findIndex(item => item.parking_id === val)
      this.batchAddForm.parking_name = list[index].parking_name
    },
    addCoupons () {
      this.batchAddForm.coupons.push({
        coupon_id: '', // 优惠券id
        coupon_name: '', // 优惠券名称
        coupon_type: null, // 优惠券类型
        coupon_count: null, // 优惠券数量
        valid_day: null, // 优惠券有效天数
        valid_time: '' // 有效期日期（当前时间+有效天数 23：59：59）
      })
    },
    delCoupons (index) {
      this.batchAddForm.coupons.splice(index, 1)
    },
    // 打开地图对话框
    openMap () {
      this.addressInnerVisible = true
      this.fngetProvinceList({
        specific_level: 1
      }, 1)
      if (this.batchAddForm.province_code) {
        // 获取市下拉列表
        this.fngetProvinceList({
          specific_level: 2,
          specific_code: this.batchAddForm.province_code
        }, 2)
      }
      if (this.batchAddForm.city_code) {
        // 获取区下拉列表
        this.fngetProvinceList({
          specific_level: 3,
          specific_code: this.batchAddForm.city_code
        }, 3)
      }
      this.addressInfo.longitude = this.batchAddForm.longitude
      this.addressInfo.latitude = this.batchAddForm.latitude
      this.addressInfo.address = this.batchAddForm.addres
      this.addressInfo.province = this.batchAddForm.province
      this.addressInfo.province_code = this.batchAddForm.province_code
      this.addressInfo.city = this.batchAddForm.city
      this.addressInfo.city_code = this.batchAddForm.city_code
      this.addressInfo.district = this.batchAddForm.district
      this.addressInfo.district_code = this.batchAddForm.district_code
      var that = this

      this.$nextTick(() => {
        that.initMap()
      })
    },
    // 获取省市区列表
    async fngetProvinceList (params, type) {
      console.log('params, type', params, type)
      // console.log('获取省市区列表');
      // type: 1 省；2 市；3 区
      const res = await getProvinceList(params)
      console.log('fngetProvinceList', res)
      if (type === 1) {
        this.provinceSelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 2) {
        this.citySelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 3) {
        this.districtSelectList = res && res.Code === 200 ? res.Data : []
      }
    },
    // 初始化地图
    initMap () {
      const that = this
      // 在定制化程度较高的项目中，开发者可能只想通过 vue-amap 引入高德地图，而部分实例化的操作直接基于高德地图的 sdk 完成。这个时候就需要 lazyAMapApiLoaderInstance
      lazyAMapApiLoaderInstance.load().then(() => {
        // 构造一个地图对象
        that.map = new AMap.Map('amap_box', {
          zoom: 12
        })
        that.map.clearMap()
        if (that.batchAddForm.longitude && that.batchAddForm.latitude) {
          that.map.setCenter([that.batchAddForm.longitude, that.batchAddForm.latitude])
        }
        // 信息窗体
        that.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30)
        })
        // 创建标记点
        that.marker = new AMap.Marker()
        that.map.plugin(['AMap.Geocoder'], () => {
          // 提供地理编码或逆地理编码功能
          that.geocoder = new AMap.Geocoder({
            radius: 1000 // 范围，默认：500
          })
          if (that.batchAddForm.address) {
            that.geocoder.getLocation(that.batchAddForm.address, function (status, result) {
              if (status === 'complete' && result.geocodes.length) {
                var lnglat = result.geocodes[0].location
                that.showInfoWindow(lnglat, that.batchAddForm.address)
              }
            })
          }
        })
        that.map.on('click', (e) => {
          console.log(e)
          that.sureLnglatMarker(e.lnglat)
        })
        AMap.event.addListener(that.marker, 'click', function () {
          // console.log("=-=-=-=-=markerclick");
          that.infoWindow.open(that.map, that.marker.getPosition())
        })
        // 输入提示
        that.map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'inputkeyword'
          }
          that.autocomplete = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: that.map
          }) // 构造地点查询类
          AMap.event.addListener(that.autocomplete, 'select', select)// 注册监听，当选中某条记录时会触发
          function select (e) {
            placeSearch.setCity(e.poi.adcode)
            placeSearch.search(e.poi.name) // 关键字查询查询
            // console.log('=-=-=-=select-==-=-=', JSON.stringify(e));
            if (e.poi.location === '') {
              that.geocoder.getLocation(`${e.poi.district}${e.poi.name}${e.poi.address}`, function (status, result) {
                if (status === 'complete' && result.geocodes.length) {
                  var lnglat = result.geocodes[0].location
                  that.sureLnglatMarker(lnglat)
                }
              })
            } else {
              that.sureLnglatMarker(e.poi.location)
            }
          }
        })
        // 定位
        AMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: false, // 是否使用高精度定位，默认:true
            timeout: 10000, // 超过10秒后停止定位，默认：5s
            buttonPosition: 'RB', // 定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: false, // 定位成功后是否自动调整地图视野到定位点
            showCircle: false // 是否显示定位精度圈
          })
          that.map.addControl(geolocation)
          // geolocation.getCityInfo(function (status, result) {
          //   console.log('定位中')

          //   if (status === 'complete') {
          //     console.log('定位成功', result)

          //     // onComplete(result)
          //   } else {
          //     console.log('定位失败', result)

          //     // onError(result)
          //   }
          // })
          geolocation.getCurrentPosition(function (status, result) {
            console.log('定位中...')
            if (status === 'complete') {
              console.log('定位成功')

              // onComplete(result)
            } else {
              console.log('定位失败', result)

              // onError(result)
            }
          })
        })
      })
    },
    // 根据点击地图经纬度，获取位置信息
    sureLnglatMarker (lnglat) {
      var that = this
      var specialPoint = ['北京市', '上海市', '天津市', '重庆市']
      var oneCountry_twoSystems = ['台湾省', '澳门特别行政区', '香港特别行政区']
      that.geocoder.getAddress(lnglat, function (status, result) {
        console.log('=-=-=-=getAddress=', JSON.stringify(result))
        if (status === 'complete' && result.regeocode) {
          const { province, city, district, adcode } = result.regeocode.addressComponent
          that.batchAddForm.province = province
          that.batchAddForm.city = city
          that.batchAddForm.district = district
          that.batchAddForm.province_code = addressjs.province_list.find(item => item.name === province).code
          //
          that.addressInfo.province = province
          that.addressInfo.city = city
          that.addressInfo.district = district
          that.addressInfo.province_code = addressjs.province_list.find(
            (item) => item.name === province
          ).code
          // 获取市区下拉列
          that.fngetProvinceList({
            specific_level: 2,
            specific_code: that.addressInfo.province_code
          }, 2)
          if (city === '') {
            if (specialPoint.indexOf(province) > -1) { // 直辖市
              that.batchAddForm.city = province
              // 直辖市
              that.addressInfo.city = province
            } else if (adcode.startsWith('4690') || adcode.startsWith('6590')) { // 海南省 青海省
              that.batchAddForm.city = district
              // 海南省 青海省
              that.addressInfo.city = district
            } else if (oneCountry_twoSystems.indexOf(province) > -1) { // 台湾 香港 澳门
              that.batchAddForm.province = province
              that.batchAddForm.city = province
              that.batchAddForm.district = province
              //
              that.addressInfo.province = province
              that.addressInfo.city = province
              that.addressInfo.district = province
            }
          }
          if (that.batchAddForm.city === '') {
            // 有部分地区的province === "中华人民共和国"；
            return
          }
          console.log('-=========that.batchAddForm.city=', that.batchAddForm.city)
          that.batchAddForm.city_code = addressjs.city_list.find(item => item.name === that.batchAddForm.city).code
          that.addressInfo.city_code = addressjs.city_list.find(
            (item) => item.name === that.addressInfo.city
          ).code
          // 获取区级下拉列
          that.fngetProvinceList({
            specific_level: 3,
            specific_code: that.addressInfo.city_code
          }, 3)
          that.addressInfo.district_code = addressjs.district_list.find(
            (item) => item.name === that.addressInfo.district
          ).code
          that.batchAddForm.district_code = addressjs.district_list.find(item => item.name === that.batchAddForm.district).code
          // console.log("-=-=-=batchAddForm-=-", JSON.stringify(that.batchAddForm));
          var address = result.regeocode.formattedAddress
          that.addressInfo.address = address
          that.addressInfo.longitude = lnglat.lng
          that.addressInfo.latitude = lnglat.lat
          that.showInfoWindow(lnglat, address)
        }
      })
    },
    // 关闭地图对话框
    closeAddress () {
      this.addressInnerVisible = false
      this.keyword = ''
      this.addressInfo = {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      }
      this.provinceSelectList = []
      this.citySelectList = []
      this.districtSelectList = []
    },
    // 展示点击地图位置信息
    showInfoWindow (lnglat, address) {
      var infoDiv = document.createElement('div')
      infoDiv.className = 'infodiv'
      var addressspan = document.createElement('span')
      addressspan.className = 'addressspan'
      addressspan.innerText = address
      infoDiv.appendChild(addressspan)
      var confirmbtn = document.createElement('input')
      confirmbtn.className = 'input-btn'
      confirmbtn.type = 'button'
      confirmbtn.value = '确定'
      var that = this
      confirmbtn.onclick = function () {
        console.log(address)
        that.batchAddForm.address = address
        that.batchAddForm.longitude = lnglat.lng
        that.batchAddForm.latitude = lnglat.lat
        that.addressInnerVisible = false
      }
      infoDiv.appendChild(confirmbtn)
      this.infoWindow.setContent(infoDiv)
      this.map.add(this.marker)
      this.map.setCenter(lnglat)
      this.marker.setPosition(lnglat)
      this.infoWindow.open(this.map, this.marker.getPosition())
    },
    // 搜索位置
    getAdress () {
      const that = this
      this.geocoder.getLocation(that.keyword, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location
          that.sureLnglatMarker(lnglat)
        }
      })
    },
    // 省下拉列发生改变触发
    provinceChange (val) {
      console.log('provinceChange', val)
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.province = ''
        this.addressInfo.city = ''
        this.addressInfo.city_code = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      };
      // 清空市区
      this.addressInfo.city = ''
      this.addressInfo.city_code = ''
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.provinceSelectList.find(item => item.specific_code === val)
      this.addressInfo.province = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      // 获取市下拉列表
      this.fngetProvinceList({
        specific_level: 2,
        specific_code: val
      }, 2)
    },
    // 市下拉列发生改变触发
    cityChange (val) {
      if (!val) {
        this.addressInfo.city = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      }
      // 清空区级数据
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.citySelectList.find(item => item.specific_code === val)
      this.addressInfo.city = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      this.autocomplete.setCity(val)
      // 获取区级下拉列表
      this.fngetProvinceList({
        specific_level: 3,
        specific_code: val
      }, 3)
    },
    // 区下拉列发生改变触发
    districtChange (val) {
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.district = ''
        return
      };
      // 筛选出符合条件的数据
      var item = this.districtSelectList.find(item => item.specific_code === val)
      this.addressInfo.district = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
    },
    // 上传图片
    upload (item) {
      // console.log("==--item=-==", item.file.uid);
      // console.log("==--item=-==", item.file.name);
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      // console.log("-----...-----" + imageName);
      // 预览图片
      const src = window.URL.createObjectURL(item.file)
      // console.log("==-=-=src-=-=", src);
      // 将图片文件转化成base64格式图片
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        // console.log("==-=-base64image=-=-", base64image);
        const res = await request.updataIcon({ name: item.file.name, strbase64: [base64image] })
        if (res.data && res.data.length > 0) {
          this.batchAddForm.business_license = res.data[0].FilePath
        } else {
          this.$msg.warning('你的图片格式有误请重新选择!')
        }
        // this.dialogForm.logo_url = url;
      }
      reader.readAsDataURL(item.file)
    },
    // 点击查看，预览图片
    showPreviewPicture () {
      this.previewPictureList = [this.batchAddForm.business_license]
      this.isShowPreviewPicture = true
    },
    // 关闭预览图片
    onClosePreviewPicture () {
      this.isShowPreviewPicture = false
      this.previewPictureList = []
    },
    couponChange (id, index) {
      var i = this.couponsSelectList.findIndex(item => item._id === id)
      console.log(i)
      this.batchAddForm.coupons[index].coupon_name = this.couponsSelectList[i].coupon_name
      this.batchAddForm.coupons[index].coupon_type = this.couponsSelectList[i].coupon_type
    },
    // 保存
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.batchAddFormRef.validate((valid) => {
        // 成功
        if (valid) {
          if (this.id) {
            this.fnUpdateBusinessManagement()
          } else {
            this.fnAddBusinessManagement()
          }
        } else {
          return false
        }
      })
    },
    async fnAddBusinessManagement () {
      var obj = {
        merchant_number: this.batchAddForm.merchant_number,
        password: this.batchAddForm.password,
        merchant_name: this.batchAddForm.merchant_name,
        valid_time: this.batchAddForm.valid_time + ' 23:59:59',
        phone: this.batchAddForm.phone,
        idcard: this.batchAddForm.idcard,
        address: this.batchAddForm.address,
        longitude: this.batchAddForm.longitude,
        latitude: this.batchAddForm.latitude,
        business_license: this.batchAddForm.business_license,
        business_range: this.batchAddForm.business_range,
        parking_id: [this.batchAddForm.parking_id],
        parking_name: this.batchAddForm.parking_name,
        principal: this.batchAddForm.principal,
        province: this.batchAddForm.province,
        province_code: this.batchAddForm.province_code,
        city: this.batchAddForm.city,
        city_code: this.batchAddForm.city_code,
        district: this.batchAddForm.district,
        district_code: this.batchAddForm.district_code
      }
      var arr = []
      this.batchAddForm.coupons.forEach(item => {
        if (item.coupon_id) {
          var date1 = new Date()
          var date2 = new Date(date1)
          date2.setDate(new Date().getDate() + Number(item.valid_day))
          item.valid_time = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate() + ' 23:59:59'
          arr.push(item)
        }
      })
      if (arr.length > 0) obj.coupons = arr
      console.log(obj)
      const res = await addBusinessManagement(obj)
      if (res && res.Code === 200) return this.$emit('closeDialog', false)
    },
    async fnUpdateBusinessManagement () {
      var obj = {
        merchant_number: this.batchAddForm.merchant_number,
        password: this.batchAddForm.password,
        merchant_name: this.batchAddForm.merchant_name,
        valid_time: this.batchAddForm.valid_time + ' 23:59:59',
        phone: this.batchAddForm.phone,
        idcard: this.batchAddForm.idcard,
        address: this.batchAddForm.address,
        longitude: this.batchAddForm.longitude,
        latitude: this.batchAddForm.latitude,
        business_license: this.batchAddForm.business_license,
        business_range: this.batchAddForm.business_range,
        principal: this.batchAddForm.principal,
        province: this.batchAddForm.province,
        province_code: this.batchAddForm.province_code,
        city: this.batchAddForm.city,
        city_code: this.batchAddForm.city_code,
        district: this.batchAddForm.district,
        district_code: this.batchAddForm.district_code
      }
      obj._id = this.id
      const res = await updateBusinessManagement(obj)
      if (res && res.Code === 200) return this.$emit('closeDialog', false)
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.form_box {
  height: 650px;
  overflow-y: auto;
  overflow-x: hidden;

  /deep/ .el-input {
    width: 65%;
  }

  /deep/ .el-select {
    width: 65%;

    .el-input--suffix {
      width: 100% !important;
    }
  }

  /deep/ .el-textarea {
    width: 85%;
  }

  .NumberInput {
    width: 150px;
    margin: 0 10px;
  }
}

.mapDialog {
  /deep/ .el-dialog__body {
    padding: 0;
    position: relative;

    .searchMap {
      display: flex;
      justify-content: start;
      margin: 15px 0 15px 15px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 99;

      .el-select.el-select--small {
        width: 120px;
      }

      .el-input {
        width: 150px;
      }
    }
  }
}

#amap_box {
  width: 100%;
  height: 500px;

  /deep/.amap-maps {
    .amap-drags {
      .amap-overlays {
        .amap-info {
          .amap-info-contentContainer {
            .amap-info-content {
              background: blanchedalmond;

              .infodiv {
                display: flex;
                width: 150px;
                flex-direction: column;
                align-items: flex-start;

                .input-btn {
                  background: #409eff;
                  margin-top: 10px;
                  color: #ffffff;
                  font-weight: 400;
                  border: none;
                  border-radius: 2px;
                }
              }
            }

            .amap-info-sharp {
              bottom: 0;
              left: 50%;
              margin-left: -8px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid blanchedalmond !important;
            }

            .amap-info-close {
              position: absolute;
              top: 5px;
              right: 5px !important;
            }
          }
        }
      }
    }
  }
}

.tablestyle {

  // /deep/ .el-table__body-wrapper {
  //   // max-height: 100px;
  // }
  /deep/ .el-table__empty-block {
    height: 100px;
    min-height: 100px !important;
  }
}

/deep/.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-right: 40px;
}
.couponsBox{
  width: 100%;
  .el-row{
    width: 100%;
    .el-select{
      width: 100%;
    }
    .el-input{
      width: 80%;
    }
  }
}
.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}</style>
